import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Grid, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import StrataDocumentsModal from "../documents/strata-documents-modal"
import TitleHeader from "../../components/title-header"

const StrataIndexPage = () => {
  const [currentForm, setCurrentForm] = useState("");
  const strataProposalRequest = 'strataProposalRequest'
  const strataProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/10'
  return (
    <Layout>
      <SEO title="Strata Management | Korecki Real Estate Services" description="Korecki Real Estate Services is dedicated to providing strata corporations with the highest level of service and ensuring smooth building operations. With extensive experience in the Strata Management field, you can rely on Korecki Real Estate Services to provide sound advice and guidance." keywords="korecki,Real Estate,Strata Management services, Strata, Strata Management, Strata Property Management, Strata Management, Property Management, Client Property Account System"/>
      <div>
        <TitleHeader title={"Strata Management Services"}/>
        {/*The following code is not from contentful*/}
        <Col md={12} className="blocktext" style={{ backgroundColor: 'rgba(250,250,250)'}}>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <p className="middle justified-black">Korecki Real Estate Services is a locally
                owned and operated Strata Management Company. Technology and online accessibility
                form the backbone of our operations, with a personal touch and direct communication
                remaining at the forefront. We administer all building operations from our Vancouver
                office and pride ourselves on providing the same or next business day responses to all inquiries.
                Please see below a brief outline of our services and operating procedures.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">Transitions</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="middle justified-black">Accurate record-keeping and understanding of building
                operations are crucial to any successful transition. Therefore, the Transitions Team works
                closely with all stakeholders to index new strata records and implement standard operating
                procedures. The process facilitates a smooth transition and forms the basis for continued building operations.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">Technology</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">As a future-ready, paperless company, we utilize a suite
                of products to ensure timely access to information, collaborative team communications, and
                tracking of ongoing tasks and directives. </p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="Yardi Voyager" width="35px" src={require('../../images/yardi.png')}/> Yardi Voyager
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">Using Yardi Voyager, an industry-leading
                accounting and strata management software, we consolidate our accounting, operations, and
                maintenance management on a mobile online platform. Providing owners and council members
                online access to strata documents and maintenance calendars/requests, along with various
                payment options reducing overall strata arrears.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="monday.com" width="50px" src={require('../../images/monday-logo.png')} /> Monday.com
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">Using Monday.com
                project management software, we track all ongoing building objectives and meeting
                directives. Thereby ensuring continuity of work regardless of vacations and absences.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="monday.com" width="70px" src={require('../../images/slack.jpg')}/> Slack
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">Using Slack messaging,
                our team harnesses the power of a hivemind. From internal communications to advice
                on operational issues and after-hours emergencies, team members draw on the expertise
                of the entire organization day or night.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="monday.com" width="55px" src={require('../../images/sharepoint.jpg')} /> Microsoft Sharepoint
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">Using Microsoft SharePoint
                and SharePoint Workflows, we ensure the accurate filing of strata records and workflows
                for all document types. From bylaw complaint validations to issuing fines and filing
                contracts, we have the required workflows to ensure proper oversight and compliance.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="monday.com" width="55px" src={require('../../images/cloud-logo.png')} /> CLOUDS, PHONES & VPN’s
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">As a fully digital company,
                we utilize cloud storage, VOIP phones, and VPN network access. These services allow our
                team to work seamlessly from home or the office while providing our senior team with
                top-level oversight.</p>
            </Row>
          </section>
          <section>
            <Row className="custom-sm-flex-order">
              <h4 className="text-uppercase font-weight-bold small-h2 middle-subsection">
                <img alt="monday.com" width="60px" src={require('../../images/lock.png')} /> Security
              </h4>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle-subsection justified-black">Data security is our top
                priority. All data is stored within Canada and encrypted at industry standards.
                Our network infrastructure is designed and managed by local professionals who
                continuously monitor it for threats and intrusions.  </p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">Standard Operating Procedures</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">No two strata corporations are alike,
                but they are all bound by: The Strata Property Act, the Personal Information Protection Act,
                the Real Estate Services Act, and the ever-evolving Civil Resolution Tribunal/Supreme Court
                of BC decisions. As a management company, we have developed rigorous operating procedures
                ensuring that our clients comply with the individual acts and provisions.
                <br />
                <br />
                All repetitive and standard administrative/accounting tasks have natural
                workflows, eliminating the need for council reminders or follow-ups. The process
                has allowed our councils to reduce their involvement in mundane activities, and focus on
                essential operational tasks, and setting unified long-term goals under the expert guidance
                of our Agents.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">Trades & Service Providers</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">We understand current labor markets and
                work with local arms-length vendors. We are utilizing economies of scale to obtain the
                best-priced quality vendors/services while adhering to regimented screening and bidding
                processes to secure multiple bids wherever possible.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">Warranty Claims</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black" >We understand the Homeowner Protection Act
                and have relationships with top industry professionals, enabling us to guide our clients through
                the various warranty claim milestones. Over the years, we have helped numerous clients better
                understand their warranty coverages and secure necessary warrantable repairs.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <h1 className="text-uppercase font-weight-bold small-h1 middle">Emergency Response</h1>
              <p className="text-md text-color-dark"></p>
            </Row>
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">We guarantee a 24/7 15-minute response
                time to building emergencies, provided by our local on-call Strata Agents. The Agents are
                skilled at determining the severity of emergencies and dispatching necessary vendors.
                In addition, the Agents utilize building-specific info sheets to dispatch trades and connect
                to our entire workforce through our internal slack messaging service.</p>
            </Row>
          </section>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <p className="no-h middle justified-black">We understand that changing management
                companies is a significant decision, and we welcome the opportunity to discuss how we
                can improve your building operations.
                <br />
                <br />
                Contact us today for a no obligations Strata Management Proposal.</p>
            </Row>
          </section>
          <section className="last-section background">
            <div className="row center">
              <Row className="pt-4">
                <button
                  className="btn btn-outline btn-primary-1 text-uppercase parallax-button"
                  onClick={() => setCurrentForm(strataProposalRequest)}>
                  Strata Management Proposal Request
                  <StrataDocumentsModal
                    open={currentForm===strataProposalRequest}
                    openModal={setCurrentForm}
                    documentUrl={strataProposalRequestUrl}/>
                </button>
              </Row>
            </div>
          </section>
        </Col>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
      allContentfulStrataSection {
        edges {
          node {
            order
            title
            description {
              description
            }
            strataSubsections {
              title
              featureList
              order
              image {
                file {
                  url
                }
              }
            }
          }
        }
     }
  }
  `
export default StrataIndexPage
